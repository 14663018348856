@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url(data:image/gif;base64,ZXhwb3J0IGRlZmF1bHQgX193ZWJwYWNrX3B1YmxpY19wYXRoX18gKyAiMTc4M2EwZjFhZjViMjkzM2M0ZTg5NjgyOTk1Mzk3YzEuZ2lmIjs=) center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "serif";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "serif";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.black {
  color: #000 !important; }

.black-bg {
  background-color: #000; }

.white {
  color: #fff !important; }

.white-bg {
  background-color: #fff; }

.red {
  color: #FC5F6A !important; }

.red-bg {
  background-color: #FC5F6A; }

.orange {
  color: #FE9E47 !important; }

.orange-bg {
  background-color: #FE9E47; }

.grey {
  color: #444444 !important; }

.grey-bg {
  background-color: #444444; }

.light-grey {
  color: #F0F0F0 !important; }

.light-grey-bg {
  background-color: #F0F0F0; }

.brown {
  color: #41362F !important; }

.brown-bg {
  background-color: #41362F; }

::-moz-selection {
  background: #FC5F6A;
  color: #fff; }

::selection {
  background: #FC5F6A;
  color: #fff; }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  font-family: "freight-sans-pro", sans-serif;
  margin: 0; }
  body.menu-active {
    overflow: hidden; }

.tac {
  text-align: center; }

.link {
  color: #FC5F6A;
  text-decoration: none;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase; }

.link-container {
  display: block;
  text-align: center; }

p, ul li {
  font-size: 18px;
  line-height: 26px; }

.heading {
  font-size: 20px;
  line-height: 22px;
  position: relative;
  color: #FC5F6A;
  margin-top: 10px;
  text-transform: uppercase; }
  .heading:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 1px;
    top: -10px;
    left: 0;
    background-color: #FC5F6A; }

.container {
  width: 90%;
  max-width: 1800px;
  margin: 0 auto; }

nav {
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0; }
  nav .container {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  nav .logo {
    width: 415px;
    height: 58px; }
    @media (max-width: 1300px) {
      nav .logo {
        width: 250px;
        height: 35px; } }
    @media (max-width: 400px) {
      nav .logo {
        width: 210px;
        height: 30px; } }
    nav .logo a {
      display: block; }
      nav .logo a img {
        display: block;
        max-width: 100%; }
  nav .links ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    font-size: 20px;
    font-weight: 300; }
    nav .links ul li {
      display: inline-block;
      margin-right: 10px; }
      nav .links ul li:last-child {
        margin-right: 0; }
      nav .links ul li a {
        font-weight: 300;
        margin: 0;
        text-decoration: none;
        color: #fff; }
      @media (min-width: 851px) {
        nav .links ul li a.active {
          color: #FC5F6A; }
        nav .links ul li.contact a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 18px; } }
  @media (max-width: 850px) {
    nav .links {
      display: none; }
      nav .links.active {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100vh;
        background-color: rgba(252, 95, 106, 0.9);
        width: 100%; }
        nav .links.active ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          min-height: 100vh;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          nav .links.active ul li {
            width: 100%;
            text-align: center;
            font-size: 30px;
            margin-bottom: 5vh; }
            nav .links.active ul li a {
              text-underline-position: under; }
              nav .links.active ul li a:hover {
                text-decoration: underline; } }
  @media (max-width: 400px) {
    nav .links.active ul li {
      margin-bottom: 25px; }
      nav .links.active ul li a {
        font-size: 26px;
        line-height: 30px; } }
  nav .menu {
    z-index: 1;
    cursor: pointer;
    width: calc(21px + 18px); }
    @media (min-width: 851px) {
      nav .menu {
        display: none; } }
    nav .menu .dot {
      width: 7px;
      height: 7px;
      margin: 3px;
      display: inline-block;
      float: left;
      border-radius: 50%;
      background-color: white;
      opacity: 1;
      -webkit-transition: opacity 0.25s;
      -o-transition: opacity 0.25s;
      transition: opacity 0.25s; }
    @media (max-width: 400px) {
      nav .menu {
        width: calc(15px + 18px); }
        nav .menu .dot {
          width: 5px;
          height: 5px;
          margin: 3px; } }
    nav .menu.active .dot:nth-child(even) {
      opacity: 0; }

.contact-us-fixed {
  text-transform: uppercase;
  text-decoration: none;
  bottom: 80px;
  left: -30px;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: absolute;
  z-index: 1; }
  @media (max-width: 1200px) {
    .contact-us-fixed {
      left: -45px; } }
  @media (max-width: 768px) {
    .contact-us-fixed {
      display: none; } }
  .contact-us-fixed:after {
    content: '';
    display: inline-block;
    height: 2px;
    width: 35px;
    margin-left: 10px;
    background-color: #FC5F6A;
    vertical-align: middle; }
  .contact-us-fixed.white:after {
    background-color: #fff; }

.social-fixed {
  z-index: 1;
  position: fixed;
  bottom: 25px;
  right: 25px;
  mix-blend-mode: darken;
  opacity: 0.65;
  -webkit-filter: invert(1);
          filter: invert(1); }
  @media (max-width: 1200px) {
    .social-fixed {
      right: 15px;
      bottom: 15px; } }
  @media (max-width: 550px) {
    .social-fixed {
      right: 5px;
      bottom: 5px; } }
  @media (max-width: 450px) {
    .social-fixed {
      display: none; } }
  .social-fixed ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none; }
    .social-fixed ul li a {
      display: block;
      margin-bottom: 10px; }
      .social-fixed ul li a img {
        width: 55px;
        height: 55px;
        display: block; }
        @media (max-width: 1200px) {
          .social-fixed ul li a img {
            width: 40px;
            height: 40px; } }
        @media (max-width: 550px) {
          .social-fixed ul li a img {
            width: 35px;
            height: 35px; } }
    .social-fixed ul li:last-child a {
      margin-bottom: 0; }

.banner {
  background-size: cover;
  background-position: center center;
  padding-bottom: 25px;
  min-height: 500px;
  height: 50vh;
  max-height: 750px;
  color: #fff; }
  @media (max-width: 550px) {
    .banner {
      min-height: 300px;
      height: 25vh;
      max-height: 500px; } }
  .banner.homepage {
    max-height: 1000px;
    height: 100vh;
    color: #fff; }
    @media (max-width: 991px) {
      .banner.homepage {
        height: 90vh; } }
    .banner.homepage:before {
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      height: 50%; }
    .banner.homepage .wrapper .content {
      max-width: 800px;
      height: 100%; }
    .banner.homepage .title {
      color: inherit; }
    .banner.homepage .links {
      width: 100%;
      text-transform: uppercase; }
      .banner.homepage .links a {
        text-decoration: none;
        color: inherit;
        margin: 10px;
        padding-top: 5px;
        position: relative; }
        .banner.homepage .links a:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 1px;
          background-color: #FC5F6A; }
  .banner:before {
    content: '';
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.75)), to(rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    height: 150px;
    width: 100%;
    max-height: 500px;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 550px) {
      .banner:before {
        height: 100px; } }
  .banner.bottom-bg {
    background-position: bottom; }
  .banner.with-badge {
    position: relative; }
    .banner.with-badge .badge {
      z-index: 1;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      left: calc(50% - 25px);
      bottom: -25px;
      -webkit-transition: width 0.25s, height 0.25s, left 0.25s, bottom 0.25s;
      -o-transition: width 0.25s, height 0.25s, left 0.25s, bottom 0.25s;
      transition: width 0.25s, height 0.25s, left 0.25s, bottom 0.25s; }
      @media (max-width: 600px) {
        .banner.with-badge .badge {
          height: 30px;
          width: 30px;
          left: calc(50% - 15px);
          bottom: -15px; } }
  .banner.blog-banner .wrapper .content {
    max-width: 1000px; }
    @media (max-width: 991px) {
      .banner.blog-banner .wrapper .content .title {
        font-size: 40px;
        line-height: 44px; } }
    @media (max-width: 550px) {
      .banner.blog-banner .wrapper .content .title {
        font-size: 28px;
        line-height: 32px; } }
  .banner .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-line-pack: end;
        align-content: flex-end;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .banner .wrapper .content {
      text-align: center;
      width: 90%;
      max-width: 500px;
      margin: 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-line-pack: end;
          align-content: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end; }
      .banner .wrapper .content .title {
        width: 100%;
        font-weight: 700; }
        @media (min-width: 992px) {
          .banner .wrapper .content .title {
            font-size: 45px;
            line-height: 50px; } }
        @media (max-width: 991px) and (min-width: 451px) {
          .banner .wrapper .content .title {
            font-size: 30px;
            line-height: 40px; } }
        @media (max-width: 450px) {
          .banner .wrapper .content .title {
            font-size: 25px;
            line-height: 35px; } }

.text-block {
  background-color: #fff;
  padding: 150px 0 175px; }
  @media (max-width: 1024px) {
    .text-block {
      padding: 100px 0 125px; } }
  @media (max-width: 768px) {
    .text-block {
      padding: 75px 0 100px; } }
  @media (max-width: 500px) {
    .text-block {
      padding: 50px 0 75px; } }
  .text-block .container {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto; }
  .text-block p {
    font-size: 24px;
    line-height: 34px;
    color: #444444; }
    @media (max-width: 1024px) {
      .text-block p {
        font-size: 20px;
        line-height: 30px; } }
    @media (max-width: 768px) {
      .text-block p {
        font-size: 18px;
        line-height: 28px; } }
    .text-block p span {
      color: #FC5F6A;
      font-weight: 700; }
  .text-block .title {
    font-size: 46px;
    line-height: 48px;
    color: #FC5F6A;
    font-weight: 700; }
    @media (max-width: 1024px) {
      .text-block .title {
        font-size: 40px;
        line-height: 42px; } }
    @media (max-width: 768px) {
      .text-block .title {
        font-size: 36px;
        line-height: 38px; } }
    @media (max-width: 500px) {
      .text-block .title {
        font-size: 32px;
        line-height: 34px; } }
  .text-block .sub-title {
    font-size: 32px;
    line-height: 34px;
    color: #444444;
    font-weight: 700; }
    @media (max-width: 1024px) {
      .text-block .sub-title {
        font-size: 26px;
        line-height: 28px; } }
    @media (max-width: 768px) {
      .text-block .sub-title {
        font-size: 22px;
        line-height: 24px; } }
    @media (max-width: 500px) {
      .text-block .sub-title {
        font-size: 18px;
        line-height: 20px; } }
  .text-block a {
    color: #FC5F6A; }
    .text-block a.link {
      margin: 0 auto; }
  .text-block.about-us {
    padding-bottom: 50px; }

.boxes {
  background-color: #fff; }
  .boxes .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .boxes .box {
    background-color: #F0F0F0;
    color: #444444;
    width: 25%;
    height: 22.25vw;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none; }
    @media (min-width: 2020px) {
      .boxes .box {
        height: 450px; } }
    @media (max-width: 991px) {
      .boxes .box {
        width: 50%;
        height: 44.25vw; } }
    @media (max-width: 475px) {
      .boxes .box {
        width: 100%;
        height: 86vw;
        margin: 0; } }
    .boxes .box img {
      max-height: 25%; }
    .boxes .box .breaker {
      height: 5px;
      width: 110px;
      background-color: #FE9E47;
      border-radius: 1px;
      margin: 25px auto; }
      @media (max-width: 991px) {
        .boxes .box .breaker {
          height: 3px; } }
      @media (max-width: 550px) {
        .boxes .box .breaker {
          height: 2px; } }
    .boxes .box p {
      margin: 0;
      font-size: 20px;
      line-height: 22px; }
      @media (max-width: 1300px) {
        .boxes .box p {
          font-size: 18px;
          line-height: 20px; } }
      @media (max-width: 1024px) {
        .boxes .box p {
          font-size: 16px;
          line-height: 18px; } }
    .boxes .box.gradient-bg {
      background: -o-linear-gradient(45deg, #fb2d82 0%, #fe9e47 100%);
      background: linear-gradient(45deg, #fb2d82 0%, #fe9e47 100%);
      color: #fff; }
      .boxes .box.gradient-bg .breaker {
        background-color: #fff; }
    .boxes .box.image img {
      max-height: initial;
      display: block;
      height: 100%;
      width: 100%; }
  .boxes.boxes-working .section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (max-width: 1200px) {
      .boxes.boxes-working .section {
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; } }
    .boxes.boxes-working .section.culture .copy {
      padding-right: 0; }
    .boxes.boxes-working .section.team .copy {
      padding-left: 0; }
    @media (max-width: 991px) {
      .boxes.boxes-working .section.team {
        margin-top: 50px; }
        .boxes.boxes-working .section.team .box {
          -webkit-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1; }
        .boxes.boxes-working .section.team .copy {
          -webkit-box-ordinal-group: 3;
              -ms-flex-order: 2;
                  order: 2; } }
    @media (max-width: 991px) {
      .boxes.boxes-working .section .box {
        width: 100%;
        height: 200px; } }
    .boxes.boxes-working .section .copy {
      padding: 50px;
      width: 75%; }
      @media (max-width: 1200px) {
        .boxes.boxes-working .section .copy {
          padding: 0 25px 25px; } }
      @media (max-width: 991px) {
        .boxes.boxes-working .section .copy {
          width: 100%;
          padding: 25px 0; } }
      .boxes.boxes-working .section .copy p {
        color: #444444; }
      .boxes.boxes-working .section .copy ul li {
        color: #444444; }
      .boxes.boxes-working .section .copy .heading {
        color: #FC5F6A; }
      .boxes.boxes-working .section .copy a {
        color: #FC5F6A; }
  .boxes.boxes-services .box {
    position: relative;
    cursor: pointer; }
    @media (max-width: 991px) and (min-width: 501px) {
      .boxes.boxes-services .box {
        width: calc(50% - 5px);
        height: 200px;
        margin-right: 10px;
        margin-bottom: 10px; }
        .boxes.boxes-services .box:nth-child(even) {
          margin-right: 0; } }
    @media (max-width: 500px) {
      .boxes.boxes-services .box {
        width: 100%;
        height: 200px;
        margin-bottom: 10px; } }
    .boxes.boxes-services .box:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      content: 'View More'; }
      @media (max-width: 991px) {
        .boxes.boxes-services .box:after {
          bottom: 12px; } }
    .boxes.boxes-services .box.active:after {
      content: 'View Less'; }
  .boxes.boxes-services-5 .box {
    width: 20%;
    height: 18vw;
    padding: 5px; }
    @media (max-width: 1350px) and (min-width: 992px) {
      .boxes.boxes-services-5 .box .breaker {
        margin: 10px auto; } }
    @media (min-width: 2020px) {
      .boxes.boxes-services-5 .box {
        height: 350px; } }
    @media (max-width: 1350px) and (min-width: 992px) {
      .boxes.boxes-services-5 .box {
        height: 22vw; } }
    @media (max-width: 991px) {
      .boxes.boxes-services-5 .box {
        width: 50%;
        height: 44.25vw; } }
    @media (max-width: 475px) {
      .boxes.boxes-services-5 .box {
        width: 100%;
        height: 86vw;
        margin: 0; } }
    @media (max-width: 991px) and (min-width: 501px) {
      .boxes.boxes-services-5 .box {
        width: calc(50% - 5px);
        height: 200px;
        margin-right: 10px;
        margin-bottom: 10px; }
        .boxes.boxes-services-5 .box:nth-child(even) {
          margin-right: 0; } }

.services-popout {
  display: none;
  color: #444444; }
  .services-popout.active {
    display: block; }
  .services-popout .heading {
    padding-right: 30px; }
  .services-popout .container {
    position: relative;
    background-color: #F0F0F0;
    padding: 20px; }
  .services-popout .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }
    .services-popout .close span {
      width: 30px;
      height: 2px;
      background-color: #FE9E47;
      display: block; }
      .services-popout .close span:first-child {
        -webkit-transform-origin: top left;
            -ms-transform-origin: top left;
                transform-origin: top left;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        position: absolute;
        top: 0;
        left: 0; }
      .services-popout .close span:last-child {
        -webkit-transform-origin: top right;
            -ms-transform-origin: top right;
                transform-origin: top right;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        position: absolute;
        top: 0;
        right: 0; }
    @media (max-width: 600px) {
      .services-popout .close {
        width: 15px;
        height: 15px; }
        .services-popout .close span {
          width: 22.5px; } }
  @media (min-width: 651px) {
    .services-popout .lists {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .services-popout .lists ul {
        width: 50%;
        display: inline-block; } }
  @media (max-width: 650px) {
    .services-popout .lists ul {
      padding-left: 20px; } }

.blog {
  padding: 150px 0;
  background-color: #fff; }
  @media (max-width: 1400px) {
    .blog {
      padding: 100px 0; } }
  @media (max-width: 768px) {
    .blog {
      padding: 50px 0; } }
  .blog .link-container {
    margin-top: 50px; }
  .blog .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .blog .article {
    border: 1px solid #444444;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: calc(50% / 3);
    min-height: 200px;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-right: 25px;
    position: relative;
    padding: 25px; }
    .blog .article:before {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      left: 25px;
      width: 30px;
      height: 1px;
      background-color: #FE9E47;
      z-index: 1; }
    .blog .article p {
      margin: 10px 0 0;
      color: #444444;
      z-index: 1; }
      .blog .article p.date {
        font-size: 14px;
        line-height: 16px; }
      .blog .article p.title {
        font-weight: 700; }
    .blog .article:first-child {
      width: calc(50% + 50px);
      background-size: cover;
      background-position: center center; }
      .blog .article:first-child:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5); }
      .blog .article:first-child p {
        color: #fff; }
    .blog .article:last-child {
      margin-right: 0; }
  @media (max-width: 1400px) {
    .blog .container {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
    .blog .article {
      width: calc(33.33% - 25px);
      margin-bottom: 25px; }
      .blog .article:first-child {
        margin-right: 0;
        width: 100%; } }
  @media (max-width: 700px) {
    .blog .article {
      display: none; }
      .blog .article:first-child {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }

.twitter {
  background-color: #F0F0F0;
  padding-top: 30px;
  padding-bottom: 30px; }
  .twitter .container .wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 0 0;
    gap: 5%; }
    @media (min-width: 501px) {
      .twitter .container .wrapper {
        margin-right: -20px; } }
    .twitter .container .wrapper .left {
      width: 50%; }
      @media (max-width: 768px) {
        .twitter .container .wrapper .left {
          width: 100%; } }
    .twitter .container .wrapper .right {
      width: 50%; }
      @media (min-width: 993px) {
        .twitter .container .wrapper .right {
          margin-top: 50px; } }
      @media (max-width: 768px) {
        .twitter .container .wrapper .right {
          width: 100%; } }
    .twitter .container .wrapper .tweet {
      color: #444444;
      text-decoration: none;
      padding: 10px;
      width: calc(20% - 20px);
      margin-right: 20px;
      background-color: white; }
      .twitter .container .wrapper .tweet .date {
        margin-top: 0;
        color: #FC5F6A; }
      .twitter .container .wrapper .tweet .text {
        margin-bottom: 0; }
      @media (max-width: 1400px) {
        .twitter .container .wrapper .tweet .date, .twitter .container .wrapper .tweet .text {
          font-size: 14px;
          line-height: 20px; } }
    @media (max-width: 992px) {
      .twitter .container .wrapper {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .twitter .container .wrapper .tweet {
          width: calc(50% - 20px);
          margin-bottom: 10px; }
          .twitter .container .wrapper .tweet:last-child {
            display: none; } }
    @media (max-width: 500px) {
      .twitter .container .wrapper .tweet {
        width: 100%;
        margin-right: 0; } }
    .twitter .container .wrapper ul.social-links {
      margin: 0;
      padding-left: 0;
      list-style-type: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 20px;
      mix-blend-mode: darken;
      opacity: 0.65;
      -webkit-filter: invert(1);
      filter: invert(1); }
      @media (max-width: 768px) {
        .twitter .container .wrapper ul.social-links {
          margin-bottom: 20px; } }
      .twitter .container .wrapper ul.social-links li a {
        display: block;
        margin-bottom: 10px; }
        .twitter .container .wrapper ul.social-links li a img {
          width: 55px;
          height: 55px;
          display: block; }
          @media (max-width: 1200px) {
            .twitter .container .wrapper ul.social-links li a img {
              width: 40px;
              height: 40px; } }
          @media (max-width: 550px) {
            .twitter .container .wrapper ul.social-links li a img {
              width: 35px;
              height: 35px; } }
      .twitter .container .wrapper ul.social-links li:last-child a {
        margin-bottom: 0; }

footer {
  background-color: #FC5F6A;
  padding-top: 45px;
  padding-bottom: 25px; }
  @media (max-width: 650px) {
    footer {
      padding: 40px 0; } }
  footer .return-top {
    position: absolute;
    left: calc(100% - 130px);
    top: 0;
    width: 22px;
    height: 12px;
    cursor: pointer; }
    @media (max-width: 650px) {
      footer .return-top {
        display: none; } }
  footer a, footer p {
    color: #fff;
    margin: 0;
    font-size: 18px;
    line-height: 25px; }
  footer a {
    text-decoration: none; }
  footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  footer .right,
  footer .siteby {
    width: 130px; }
  footer .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    footer .top img {
      display: block; }
    footer .top .left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      footer .top .left .logo {
        width: 250px;
        display: block; }
        footer .top .left .logo img {
          width: 100%; }
      footer .top .left .address-contacts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 10px 10px 10px 0; }
        footer .top .left .address-contacts .contacts .address {
          margin-bottom: 15px; }
        footer .top .left .address-contacts .contacts a {
          text-decoration: underline; }
        footer .top .left .address-contacts .social-icons {
          margin-top: 5px; }
          footer .top .left .address-contacts .social-icons a {
            text-decoration: none;
            margin-right: 10px;
            display: inline-block; }
            footer .top .left .address-contacts .social-icons a:last-child {
              margin-right: 0; }
  footer .copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 25px; }
    @media (min-width: 651px) {
      footer .copyright {
        position: relative;
        position: relative;
        min-height: 50px;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end; }
        footer .copyright .prca,
        footer .copyright .siteby {
          display: block;
          width: 130px; }
        footer .copyright p {
          width: calc(100% - 260px);
          text-align: center; } }
    footer .copyright a, footer .copyright p {
      font-size: 16px;
      line-height: 23px; }
    footer .copyright a, footer .copyright img {
      display: block; }
  @media (max-width: 650px) {
    footer .top {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      footer .top .left, footer .top .right {
        width: 100%; }
      footer .top .left .logo {
        margin: 15px auto; }
      footer .top .left .contacts {
        text-align: center;
        width: 100%;
        margin-bottom: 15px; }
      footer .top .right .links {
        text-align: center; }
    footer .copyright {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      footer .copyright p, footer .copyright .siteby {
        display: block;
        width: 100%;
        text-align: center; }
      footer .copyright .prca {
        margin: 0 auto 15px; } }

.intro-about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .intro-about .link-container {
    width: 100%;
    margin: 50px auto; }
  .intro-about .image {
    width: 30%;
    background-size: cover;
    background-position: center center; }
    @media (min-width: 551px) {
      .intro-about .image {
        margin-right: 5%; } }
  .intro-about .copy {
    width: 65%;
    color: #444444; }
  @media (max-width: 550px) {
    .intro-about .image {
      width: 100%;
      height: 30vw;
      margin-bottom: 25px; }
    .intro-about .copy {
      width: 100%; } }

.team {
  margin-bottom: 50px; }
  .team .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -20px; }
    .team .wrapper .heading {
      width: 100%; }
  .team .name {
    font-size: 26px;
    line-height: 30px;
    font-weight: 700; }
  .team .role {
    font-size: 14px;
    line-height: 16px; }
  .team .image {
    width: calc(33.33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer; }
    @media (max-width: 450px) {
      .team .image {
        width: calc(100% - 20px); } }
    .team .image img.member {
      height: auto;
      max-width: 100%;
      display: block; }
    .team .image .copy {
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.75);
      position: absolute;
      text-align: center;
      padding: 10px;
      -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px); }
      @media (max-width: 900px) {
        .team .image .copy {
          display: none; } }
      .team .image .copy p {
        margin: 0; }
      .team .image .copy .name, .team .image .copy .role {
        margin: 5px 0;
        color: #41362F; }
      @media (max-width: 991px) {
        .team .image .copy .name {
          font-size: 20px;
          line-height: 24px; } }
  .team .bio-container {
    width: 100%;
    display: block;
    margin-bottom: 25px; }
  .team .bio {
    width: 100%;
    display: none; }
    .team .bio.active {
      display: block; }
    .team .bio .bio-copy {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .team .bio .bio-copy .left, .team .bio .bio-copy .right {
        width: calc(50% - 10px); }
      @media (max-width: 1500px) {
        .team .bio .bio-copy {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .team .bio .bio-copy .left, .team .bio .bio-copy .right {
            width: calc(100% - 20px); }
          .team .bio .bio-copy .right {
            margin-top: -18px; } }
    .team .bio .name {
      color: #FC5F6A;
      margin-top: 0;
      margin-bottom: 10px; }
    .team .bio .role {
      margin: 10px auto 0;
      color: #444444; }
    .team .bio .bio-copy {
      color: #444444; }
      .team .bio .bio-copy p {
        font-size: 18px;
        line-height: 24px; }

.form-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .form-container .title {
    width: 100%;
    color: #444444;
    margin: 100px auto 125px;
    font-size: 32px;
    line-height: 34px;
    font-weight: 700;
    text-align: center; }
  .form-container .contact-form {
    width: 100%;
    margin-bottom: 120px; }
    .form-container .contact-form form {
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .form-container .contact-form form .feedback, .form-container .contact-form form .bottom {
        width: 100%; }
      .form-container .contact-form form .feedback {
        margin: 20px 0; }
        .form-container .contact-form form .feedback-error {
          color: #D80101; }
        .form-container .contact-form form .feedback-success {
          color: #00980D; }
      .form-container .contact-form form .left .field {
        padding-top: 20px; }
      @media (min-width: 992px) {
        .form-container .contact-form form .left {
          margin-right: 10px; }
          .form-container .contact-form form .left .field {
            padding-top: 15px; } }
      @media (min-width: 992px) {
        .form-container .contact-form form .right {
          margin-left: 10px; } }
      .form-container .contact-form form .left, .form-container .contact-form form .right {
        width: calc(50% - 10px); }
        @media (max-width: 991px) {
          .form-container .contact-form form .left, .form-container .contact-form form .right {
            width: 100%; } }
        .form-container .contact-form form .left label, .form-container .contact-form form .left input, .form-container .contact-form form .left textarea, .form-container .contact-form form .right label, .form-container .contact-form form .right input, .form-container .contact-form form .right textarea {
          width: 100%;
          font-size: 18px;
          font-family: "freight-sans-pro", sans-serif; }
          .form-container .contact-form form .left label:focus, .form-container .contact-form form .left input:focus, .form-container .contact-form form .left textarea:focus, .form-container .contact-form form .right label:focus, .form-container .contact-form form .right input:focus, .form-container .contact-form form .right textarea:focus {
            outline: none; }
        .form-container .contact-form form .left .field, .form-container .contact-form form .right .field {
          position: relative;
          width: 100%; }
          .form-container .contact-form form .left .field.required label:after, .form-container .contact-form form .right .field.required label:after {
            content: '*';
            display: inline-block;
            margin-left: 3px;
            color: #FC5F6A; }
          .form-container .contact-form form .left .field input, .form-container .contact-form form .left .field textarea, .form-container .contact-form form .right .field input, .form-container .contact-form form .right .field textarea {
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            border: none; }
            @media (max-width: 991px) {
              .form-container .contact-form form .left .field input, .form-container .contact-form form .left .field textarea, .form-container .contact-form form .right .field input, .form-container .contact-form form .right .field textarea {
                font-size: 16px; } }
          .form-container .contact-form form .left .field textarea, .form-container .contact-form form .right .field textarea {
            background-attachment: local;
            background-image: -o-repeating-linear-gradient(white, white 57px, #444444 57px, #444444 58px, white 58px);
            background-image: repeating-linear-gradient(white, white 57px, #444444 57px, #444444 58px, white 58px);
            resize: none;
            line-height: 58px;
            padding: 8px 0 0 0;
            height: 200px; }
          .form-container .contact-form form .left .field input, .form-container .contact-form form .right .field input {
            height: 43px;
            border-bottom: 1px solid #444444;
            -webkit-transition: border-bottom 0.25s;
            -o-transition: border-bottom 0.25s;
            transition: border-bottom 0.25s; }
            .form-container .contact-form form .left .field input:focus, .form-container .contact-form form .right .field input:focus {
              border-bottom: 1px solid rgba(68, 68, 68, 0.5); }
            .form-container .contact-form form .left .field input.error, .form-container .contact-form form .right .field input.error {
              border-bottom-color: red; }
          .form-container .contact-form form .left .field label, .form-container .contact-form form .right .field label {
            position: absolute;
            top: 20px;
            font-size: 18px;
            -webkit-transition: top 0.25s, font-size 0.25s;
            -o-transition: top 0.25s, font-size 0.25s;
            transition: top 0.25s, font-size 0.25s;
            color: #444444; }
            @media (max-width: 991px) {
              .form-container .contact-form form .left .field label, .form-container .contact-form form .right .field label {
                font-size: 16px;
                top: 28px; } }
          .form-container .contact-form form .left .field.move-label label, .form-container .contact-form form .right .field.move-label label {
            top: 3px;
            font-size: 16px; }
            @media (max-width: 991px) {
              .form-container .contact-form form .left .field.move-label label, .form-container .contact-form form .right .field.move-label label {
                font-size: 14px;
                top: 8px; } }
      .form-container .contact-form form .bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        @media (max-width: 991px) {
          .form-container .contact-form form .bottom {
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; } }
        .form-container .contact-form form .bottom .gdpr {
          font-size: 16px;
          line-height: 18px; }
          .form-container .contact-form form .bottom .gdpr .checkbox {
            margin-top: 5px;
            margin-bottom: 10px; }
            @media (max-width: 991px) {
              .form-container .contact-form form .bottom .gdpr .checkbox {
                margin-bottom: 20px; } }
        .form-container .contact-form form .bottom .submit, .form-container .contact-form form .bottom .gdpr {
          width: 100%; }
        @media (min-width: 992px) {
          .form-container .contact-form form .bottom .gdpr {
            width: 80%; }
          .form-container .contact-form form .bottom .submit {
            text-align: right;
            width: 20%; } }
        .form-container .contact-form form .bottom button {
          cursor: pointer;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-size: 18px;
          text-transform: uppercase;
          border: none;
          background-color: transparent;
          color: #FC5F6A;
          font-family: "freight-sans-pro", sans-serif;
          font-weight: 500;
          padding: 5px 2px; }
          @media (max-width: 991px) {
            .form-container .contact-form form .bottom button {
              margin-top: 15px; } }
          .form-container .contact-form form .bottom button img {
            vertical-align: middle;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0 5px; }
        @media (max-width: 850px) {
          .form-container .contact-form form .bottom {
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
            .form-container .contact-form form .bottom .gdpr, .form-container .contact-form form .bottom .submit {
              width: 100%; }
            .form-container .contact-form form .bottom button {
              margin-top: 15px; } }

.blog-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .blog-items .filter {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .blog-items .filter img {
      margin-left: -20px;
      height: 10px;
      width: 16px;
      pointer-events: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .blog-items .filter select {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: transparent;
      border: none;
      padding-right: 25px;
      appearance: none;
      color: #FC5F6A;
      text-transform: uppercase;
      border: none;
      font-family: "freight-sans-pro", sans-serif;
      font-size: 18px; }
      .blog-items .filter select:focus {
        outline: none; }
      .blog-items .filter select::-ms-expand {
        display: none; }
      .blog-items .filter select option {
        color: #444444; }
  .blog-items .entries {
    min-height: 450px; }
    @media (min-width: 1201px) {
      .blog-items .entries {
        margin-top: 90px;
        margin-right: -10px; }
        .blog-items .entries .entry {
          width: calc(25% - 10px);
          margin-right: 10px;
          margin-bottom: 10px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .blog-items .entries .entry:hover {
            opacity: 0.9; }
        .blog-items .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .blog-items .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .blog-items .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .blog-items .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    @media (max-width: 1200px) and (min-width: 801px) {
      .blog-items .entries {
        margin-top: 50px;
        margin-right: -10px; }
        .blog-items .entries .entry {
          width: calc(33.33% - 10px);
          margin-right: 10px;
          margin-bottom: 10px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .blog-items .entries .entry:hover {
            opacity: 0.9; }
        .blog-items .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .blog-items .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .blog-items .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .blog-items .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    @media (max-width: 800px) and (min-width: 501px) {
      .blog-items .entries {
        margin-top: 50px;
        margin-right: -10px; }
        .blog-items .entries .entry {
          width: calc(50% - 10px);
          margin-right: 10px;
          margin-bottom: 10px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .blog-items .entries .entry:hover {
            opacity: 0.9; }
        .blog-items .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .blog-items .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .blog-items .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .blog-items .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    @media (max-width: 700px) {
      .blog-items .entries .copy .title {
        font-size: 22px; } }
    @media (max-width: 500px) {
      .blog-items .entries {
        margin-top: 30px;
        margin-right: -10px; }
        .blog-items .entries .entry {
          width: calc(100% - 10px);
          margin-right: 10px;
          margin-bottom: 10px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .blog-items .entries .entry:hover {
            opacity: 0.9; }
        .blog-items .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .blog-items .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .blog-items .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .blog-items .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    .blog-items .entries .entry {
      overflow: hidden;
      background-color: #F0F0F0; }
    .blog-items .entries .image-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .blog-items .entries .image-container img {
        display: block;
        height: 300px;
        width: auto;
        margin: 0 auto; }
        @media (max-width: 650px) {
          .blog-items .entries .image-container img {
            height: 200px; } }

.agency .sub-title {
  font-size: 32px;
  line-height: 34px;
  color: #444444;
  font-weight: 700;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 30px; }
  @media (max-width: 1024px) {
    .agency .sub-title {
      font-size: 26px;
      line-height: 28px; } }
  @media (max-width: 768px) {
    .agency .sub-title {
      font-size: 22px;
      line-height: 24px; } }
  @media (max-width: 500px) {
    .agency .sub-title {
      font-size: 18px;
      line-height: 20px; } }

.agency .link {
  display: inline-block;
  margin: 50px auto 0;
  cursor: pointer; }

.bullet-list {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto; }
  .bullet-list .title {
    font-size: 46px;
    line-height: 48px;
    color: #FC5F6A;
    font-weight: 700; }
    @media (max-width: 1024px) {
      .bullet-list .title {
        font-size: 40px;
        line-height: 42px; } }
    @media (max-width: 768px) {
      .bullet-list .title {
        font-size: 36px;
        line-height: 38px; } }
    @media (max-width: 500px) {
      .bullet-list .title {
        font-size: 32px;
        line-height: 34px; } }
  .bullet-list .sub-title {
    font-size: 32px;
    line-height: 34px;
    color: #444444;
    font-weight: 700;
    margin-bottom: 30px; }
    @media (max-width: 1024px) {
      .bullet-list .sub-title {
        font-size: 26px;
        line-height: 28px; } }
    @media (max-width: 768px) {
      .bullet-list .sub-title {
        font-size: 22px;
        line-height: 24px; } }
    @media (max-width: 500px) {
      .bullet-list .sub-title {
        font-size: 18px;
        line-height: 20px; } }
  .bullet-list ul {
    list-style-type: none;
    padding-left: 0;
    max-width: 800px;
    margin: 0 auto;
    padding-left: 0; }
    .bullet-list ul li {
      position: relative;
      padding-left: 20px; }
      .bullet-list ul li:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        background-color: #FE9E47;
        border-radius: 50%;
        position: absolute;
        top: 9px;
        left: 5px; }

.project {
  margin: 100px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .project .sub-title {
    font-size: 32px;
    line-height: 34px;
    color: #444444;
    font-weight: 700;
    color: #41362F;
    margin-bottom: 45px;
    text-align: center; }
    @media (max-width: 1024px) {
      .project .sub-title {
        font-size: 26px;
        line-height: 28px; } }
    @media (max-width: 768px) {
      .project .sub-title {
        font-size: 22px;
        line-height: 24px; } }
    @media (max-width: 500px) {
      .project .sub-title {
        font-size: 18px;
        line-height: 20px; } }
    @media (max-width: 500px) {
      .project .sub-title {
        font-size: 20px;
        line-height: 22px; } }
  .project img {
    width: 48px;
    height: 48px;
    margin-bottom: 45px; }
  @media (min-width: 992px) {
    .project .link {
      font-size: 20px;
      line-height: 22px; } }
  @media (max-width: 991px) and (min-width: 401px) {
    .project .link {
      font-size: 18px;
      line-height: 20px; } }
  @media (max-width: 400px) {
    .project .link {
      font-size: 16px;
      line-height: 18px; } }

.client-testimonials-block {
  position: relative; }
  .client-testimonials-block .arrows {
    position: absolute;
    bottom: -50px;
    left: 0; }
    @media (max-width: 550px) {
      .client-testimonials-block .arrows {
        bottom: -25px; } }
    .client-testimonials-block .arrows .arrow {
      height: 25px;
      width: 15px;
      cursor: pointer; }
      .client-testimonials-block .arrows .arrow.next {
        margin-left: 25px; }
      @media (max-width: 991px) {
        .client-testimonials-block .arrows .arrow {
          height: 19px;
          width: 11.5px; }
          .client-testimonials-block .arrows .arrow.next {
            margin-left: 15px; } }
      @media (max-width: 550px) {
        .client-testimonials-block .arrows .arrow {
          height: 12.5px;
          width: 7.5px; }
          .client-testimonials-block .arrows .arrow.next {
            margin-left: 10px; } }

.clients-intro .sub-title {
  margin-top: 125px;
  text-align: center;
  font-size: 32px;
  line-height: 34px;
  color: #444444;
  font-weight: 700; }
  @media (max-width: 1024px) {
    .clients-intro .sub-title {
      font-size: 26px;
      line-height: 28px; } }
  @media (max-width: 768px) {
    .clients-intro .sub-title {
      font-size: 22px;
      line-height: 24px; } }
  @media (max-width: 500px) {
    .clients-intro .sub-title {
      font-size: 18px;
      line-height: 20px; } }

.clients-case-studies {
  margin-bottom: 140px; }
  .clients-case-studies .entries {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media (min-width: 992px) {
      .clients-case-studies .entries {
        margin-top: 90px;
        margin-right: -20px; }
        .clients-case-studies .entries .entry {
          width: calc(33.33% - 20px);
          margin-right: 20px;
          margin-bottom: 20px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .clients-case-studies .entries .entry:hover {
            opacity: 0.9; }
        .clients-case-studies .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .clients-case-studies .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .clients-case-studies .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .clients-case-studies .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    @media (max-width: 991px) and (min-width: 451px) {
      .clients-case-studies .entries {
        margin-top: 90px;
        margin-right: -20px; }
        .clients-case-studies .entries .entry {
          width: calc(50% - 20px);
          margin-right: 20px;
          margin-bottom: 20px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .clients-case-studies .entries .entry:hover {
            opacity: 0.9; }
        .clients-case-studies .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .clients-case-studies .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .clients-case-studies .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .clients-case-studies .entries .copy p {
            margin: 0;
            line-height: 24px; }
        .clients-case-studies .entries .copy p {
          font-size: 16px;
          line-height: 20px; }
        .clients-case-studies .entries .copy .title {
          font-size: 23px;
          line-height: 30px; } }
    @media (max-width: 450px) {
      .clients-case-studies .entries {
        margin-top: 45px;
        margin-right: -20px; }
        .clients-case-studies .entries .entry {
          width: calc(100% - 20px);
          margin-right: 20px;
          margin-bottom: 20px;
          text-decoration: none;
          -webkit-transition: opacity 0.25s;
          -o-transition: opacity 0.25s;
          transition: opacity 0.25s; }
          .clients-case-studies .entries .entry:hover {
            opacity: 0.9; }
        .clients-case-studies .entries .copy {
          background-color: #F0F0F0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #444444; }
          .clients-case-studies .entries .copy .title {
            font-size: 26px;
            line-height: 30px;
            color: #FC5F6A;
            font-weight: 700;
            margin-bottom: 5px; }
          .clients-case-studies .entries .copy .category {
            margin: 5px 0;
            font-size: 14px;
            line-height: 18px; }
          .clients-case-studies .entries .copy p {
            margin: 0;
            line-height: 24px; } }
    .clients-case-studies .entries .entry {
      border: 3px solid #F0F0F0;
      overflow: hidden;
      position: relative;
      padding-bottom: 40px; }
      .clients-case-studies .entries .entry .copy {
        height: 100%; }
        .clients-case-studies .entries .entry .copy .link {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
          margin: auto;
          text-align: center; }
          @media (min-width: 992px) {
            .clients-case-studies .entries .entry .copy .link {
              font-size: 20px;
              line-height: 22px; } }
          @media (max-width: 991px) and (min-width: 401px) {
            .clients-case-studies .entries .entry .copy .link {
              font-size: 18px;
              line-height: 20px; } }
          @media (max-width: 400px) {
            .clients-case-studies .entries .entry .copy .link {
              font-size: 16px;
              line-height: 18px; } }
      .clients-case-studies .entries .entry img {
        max-height: 250px;
        max-width: 100%;
        margin: 0 auto;
        display: block;
        width: auto; }
        @media (max-width: 1200px) {
          .clients-case-studies .entries .entry img {
            max-height: 200px; } }
        @media (max-width: 650px) {
          .clients-case-studies .entries .entry img {
            max-height: 150px; } }
  .clients-case-studies .load-more {
    text-align: center; }
    .clients-case-studies .load-more .link {
      display: inline-block;
      margin: 50px auto 0;
      cursor: pointer; }

.clients-testimonials {
  margin-top: 50px; }
  .clients-testimonials .wrap .testimonial-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
  .clients-testimonials .testimonial {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: stretch;
        align-self: stretch; }
    .clients-testimonials .testimonial .image {
      border: 1px solid #F0F0F0;
      max-width: 300px;
      width: 15vw;
      max-height: 300px;
      height: 15vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      padding: 25px; }
      @media (max-width: 991px) {
        .clients-testimonials .testimonial .image {
          width: 25vw;
          height: 25vw; } }
      @media (max-width: 600px) {
        .clients-testimonials .testimonial .image {
          display: none; } }
      .clients-testimonials .testimonial .image .icon {
        -ms-flex-item-align: center;
            align-self: center;
        max-width: 85%;
        width: 100%;
        margin: 0 auto; }
      .clients-testimonials .testimonial .image:before {
        content: '';
        display: block;
        position: absolute;
        top: 25px;
        left: 25px;
        width: 30px;
        height: 1px;
        background-color: #FE9E47;
        z-index: 1; }
  .clients-testimonials .copy {
    background-color: #F0F0F0;
    padding-top: 25px;
    padding-bottom: 25px;
    -ms-flex-item-align: stretch;
        align-self: stretch;
    width: 100%; }
    .clients-testimonials .copy .wrap {
      width: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .clients-testimonials .copy p {
      margin: 5px 25px; }
    .clients-testimonials .copy .title {
      font-size: 26px;
      line-height: 30px;
      color: #FC5F6A;
      font-weight: 700; }
    .clients-testimonials .copy .attestant {
      font-size: 14px;
      line-height: 18px; }
    .clients-testimonials .copy .quote {
      font-size: 16px;
      line-height: 22px;
      font-style: italic; }

.case-study-logo {
  padding: 0 25px; }
  .case-study-logo img {
    max-height: 175px;
    display: block;
    margin: 0 auto; }
    @media (max-width: 991px) {
      .case-study-logo img {
        max-height: 135px; } }

.blog-single {
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 1024px; }
  @media (max-width: 991px) {
    .blog-single {
      margin-bottom: 50px; } }
  .blog-single img {
    max-width: 100%;
    height: auto; }

.blog-single, .case-study-single {
  color: #444444; }
  .blog-single .medium-insert-images figure, .case-study-single .medium-insert-images figure {
    margin: 25px 0; }
    .blog-single .medium-insert-images figure img, .case-study-single .medium-insert-images figure img {
      max-width: 100%; }
  .blog-single p, .blog-single h3, .case-study-single p, .case-study-single h3 {
    font-size: 24px;
    line-height: 30px; }
    @media (max-width: 1024px) {
      .blog-single p, .blog-single h3, .case-study-single p, .case-study-single h3 {
        font-size: 22px;
        line-height: 28px; } }
    @media (max-width: 768px) {
      .blog-single p, .blog-single h3, .case-study-single p, .case-study-single h3 {
        font-size: 20px;
        line-height: 26px; } }
    @media (max-width: 550px) {
      .blog-single p, .blog-single h3, .case-study-single p, .case-study-single h3 {
        font-size: 18px;
        line-height: 24px; } }
    .blog-single p.written-by, .blog-single p.posted, .blog-single h3.written-by, .blog-single h3.posted, .case-study-single p.written-by, .case-study-single p.posted, .case-study-single h3.written-by, .case-study-single h3.posted {
      margin: 5px; }
    .blog-single p.posted, .blog-single h3.posted, .case-study-single p.posted, .case-study-single h3.posted {
      margin-bottom: 50px; }
  .blog-single .title, .case-study-single .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    position: relative;
    padding: 25px; }
    @media (max-width: 1024px) {
      .blog-single .title, .case-study-single .title {
        font-size: 28px;
        line-height: 30px; } }
    @media (max-width: 768px) {
      .blog-single .title, .case-study-single .title {
        font-size: 24px;
        line-height: 26px; } }
    .blog-single .title:before, .case-study-single .title:before {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      left: 25px;
      width: 30px;
      height: 1px;
      background-color: #FE9E47;
      z-index: 1; }
    .blog-single .title:before, .case-study-single .title:before {
      left: calc(50% - 15px); }
    .blog-single .title:before, .case-study-single .title:before {
      top: calc(100% - 5px); }
  .blog-single h2, .case-study-single h2 {
    font-size: 32px;
    line-height: 34px; }
    @media (max-width: 991px) {
      .blog-single h2, .case-study-single h2 {
        font-size: 26px;
        line-height: 30px; } }
    @media (max-width: 400px) {
      .blog-single h2, .case-study-single h2 {
        font-size: 20px;
        line-height: 24px; } }
  .blog-single h3, .case-study-single h3 {
    position: relative;
    padding: 25px;
    display: block;
    padding-top: 40px;
    padding-bottom: 0; }
    .blog-single h3:before, .case-study-single h3:before {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      left: 25px;
      width: 30px;
      height: 1px;
      background-color: #FE9E47;
      z-index: 1; }
    .blog-single h3:before, .case-study-single h3:before {
      left: calc(50% - 15px); }
  .blog-single h2, .blog-single h3, .case-study-single h2, .case-study-single h3 {
    color: #FC5F6A; }
  .blog-single blockquote, .case-study-single blockquote {
    width: 90%;
    padding-top: 50px;
    margin: 45px auto 10px;
    font-size: 48px;
    line-height: 66px;
    font-weight: 300;
    position: relative;
    padding: 25px; }
    @media (max-width: 991px) {
      .blog-single blockquote, .case-study-single blockquote {
        font-size: 40px;
        line-height: 48px; } }
    @media (max-width: 768px) {
      .blog-single blockquote, .case-study-single blockquote {
        font-size: 35px;
        line-height: 40px; } }
    @media (max-width: 650px) {
      .blog-single blockquote, .case-study-single blockquote {
        font-size: 30px;
        line-height: 34px;
        padding-left: 0;
        padding-right: 0; } }
    @media (max-width: 500px) {
      .blog-single blockquote, .case-study-single blockquote {
        font-size: 26px;
        line-height: 32px; } }
    @media (max-width: 400px) {
      .blog-single blockquote, .case-study-single blockquote {
        font-size: 22px;
        line-height: 28px; } }
    .blog-single blockquote:after, .case-study-single blockquote:after {
      content: "\201C";
      color: #FC5F6A;
      font-size: 90px;
      font-family: Arial;
      position: absolute;
      top: 0;
      left: calc(50% - 10px);
      width: 20px; }
    .blog-single blockquote:before, .case-study-single blockquote:before {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      left: 25px;
      width: 30px;
      height: 1px;
      background-color: #FE9E47;
      z-index: 1; }
    .blog-single blockquote:before, .case-study-single blockquote:before {
      left: calc(50% - 15px); }
    .blog-single blockquote:before, .case-study-single blockquote:before {
      top: calc(100% - 5px); }
  .blog-single + .related-blog, .case-study-single + .related-blog {
    padding-top: 80px;
    padding-bottom: 125px;
    background-color: #F0F0F0; }
    @media (max-width: 991px) {
      .blog-single + .related-blog, .case-study-single + .related-blog {
        padding-top: 50px;
        padding-bottom: 50px; } }
    .blog-single + .related-blog .entries, .case-study-single + .related-blog .entries {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 auto;
      min-height: auto; }
      .blog-single + .related-blog .entries .entry .copy, .case-study-single + .related-blog .entries .entry .copy {
        background-color: white; }
      @media (max-width: 991px) {
        .blog-single + .related-blog .entries .entry .copy, .case-study-single + .related-blog .entries .entry .copy {
          padding: 10px; }
          .blog-single + .related-blog .entries .entry .copy .title, .case-study-single + .related-blog .entries .entry .copy .title {
            font-size: 22px;
            line-height: 26px; }
          .blog-single + .related-blog .entries .entry .copy .summary, .case-study-single + .related-blog .entries .entry .copy .summary {
            font-size: 16px;
            line-height: 20px; } }
      @media (max-width: 850px) {
        .blog-single + .related-blog .entries, .case-study-single + .related-blog .entries {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .blog-single + .related-blog .entries .entry, .case-study-single + .related-blog .entries .entry {
            width: calc(50% - 10px); } }
      @media (max-width: 500px) {
        .blog-single + .related-blog .entries .entry, .case-study-single + .related-blog .entries .entry {
          width: 75%;
          margin: 10px auto; } }
      @media (max-width: 425px) {
        .blog-single + .related-blog .entries .entry, .case-study-single + .related-blog .entries .entry {
          width: 100%; } }

.case-study-single {
  text-align: center;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (max-width: 991px) {
    .case-study-single {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  .case-study-single .return-case-studies {
    width: 100%;
    text-align: left; }
    .case-study-single .return-case-studies a {
      font-size: 18px;
      line-height: 20px; }
  .case-study-single .left {
    text-align: left; }
    @media (min-width: 992px) {
      .case-study-single .left {
        padding-right: 50px; } }
    @media (max-width: 1400px) {
      .case-study-single .left {
        width: 60%; } }
    @media (max-width: 1200px) {
      .case-study-single .left {
        width: 70%; } }
    @media (max-width: 991px) {
      .case-study-single .left {
        width: 100%; } }
    .case-study-single .left .title-date {
      margin-top: 20px; }
      .case-study-single .left .title-date .title {
        margin-top: 0;
        padding-top: 0;
        padding-left: 0; }
        .case-study-single .left .title-date .title:before {
          left: 0; }
    .case-study-single .left ul {
      display: block;
      margin: 25px auto; }
      @media (min-width: 992px) {
        .case-study-single .left ul {
          width: 75%; } }
    @media (max-width: 1400px) {
      .case-study-single .left p {
        font-size: 22px;
        line-height: 30px; } }
    @media (max-width: 1200px) {
      .case-study-single .left p {
        font-size: 20px;
        line-height: 26px; } }
    @media (max-width: 768px) {
      .case-study-single .left p {
        font-size: 18px;
        line-height: 24px; } }
  .case-study-single .right {
    padding-top: 25px; }
    @media (max-width: 1400px) {
      .case-study-single .right {
        width: 40%; } }
    @media (max-width: 1200px) {
      .case-study-single .right {
        width: 30%; } }
    @media (max-width: 991px) {
      .case-study-single .right {
        width: 100%; } }
    .case-study-single .right img {
      display: block;
      max-width: 100%;
      width: 100%;
      margin: 0 auto; }
  @media (min-width: 1401px) {
    .case-study-single .left, .case-study-single .right {
      width: 50%; } }

.medium-insert-buttons {
  display: none !important; }

.notfound .top {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-size: cover;
  background-position: center center; }
  .notfound .top p {
    font-size: 250px;
    font-weight: 600;
    color: white; }
    @media (max-width: 991px) {
      .notfound .top p {
        font-size: 175px; } }
    @media (max-width: 550px) {
      .notfound .top p {
        font-size: 100px; } }

.notfound .bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 992px) {
    .notfound .bottom {
      padding-top: 100px; } }
  @media (max-width: 991px) {
    .notfound .bottom {
      padding: 50px 0;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media (max-width: 550px) {
    .notfound .bottom {
      padding: 25px 0; } }
  .notfound .bottom p {
    color: #444444;
    font-size: 30px;
    line-height: 35px; }
    @media (max-width: 991px) {
      .notfound .bottom p {
        font-size: 25px;
        line-height: 28px; } }
    @media (max-width: 550px) {
      .notfound .bottom p {
        font-size: 20px;
        line-height: 22px; } }
    .notfound .bottom p.oops {
      margin: 0 auto;
      font-size: 90px;
      line-height: 110px; }
      @media (max-width: 991px) {
        .notfound .bottom p.oops {
          font-size: 75px;
          line-height: 95px; } }
      @media (max-width: 550px) {
        .notfound .bottom p.oops {
          font-size: 30px;
          line-height: 40px; } }
  .notfound .bottom a {
    color: #FC5F6A;
    font-size: 22px;
    line-height: 25px; }
    @media (max-width: 991px) {
      .notfound .bottom a {
        font-size: 18px;
        line-height: 22px; } }
    @media (max-width: 550px) {
      .notfound .bottom a {
        font-size: 16px;
        line-height: 20px; } }

.notfound .top, .notfound .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center; }
  @media (min-width: 992px) {
    .notfound .top, .notfound .bottom {
      min-height: 500px;
      height: 50vh; } }
  @media (max-width: 991px) {
    .notfound .top, .notfound .bottom {
      height: 400px; } }
  @media (max-width: 550px) {
    .notfound .top, .notfound .bottom {
      height: 250px; } }
